const appConfig = {
    apiPrefix: 'https://api.alefmenu.com',
    authenticatedEntryPath: '/restaurants',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/confirm-password',
    locale: 'ar',
    enableMock: false,
}

export default appConfig
